export var NavigationStep;
(function (NavigationStep) {
    NavigationStep["MAIN"] = "main";
    NavigationStep["TRIPS"] = "trips";
    NavigationStep["SEARCH"] = "search";
    NavigationStep["INFO"] = "info";
    NavigationStep["PROFILE"] = "profile";
    NavigationStep["PAGE"] = "page";
    NavigationStep["ROUTES"] = "routes";
})(NavigationStep || (NavigationStep = {}));
