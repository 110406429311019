var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { NavigationStep } from "./types";
import { NewSlimCompassLinear, NewSlimHomeLinear, NewSlimInfoLinear, NewSlimProfileLinear, NewSlimSearchTicketFilled, NewSlimSearchTicketLinear, } from "@s7/icons";
var NEW_PROFILE_DOMAIN = process.env.REACT_APP_PROFILE_REDIRECT_DOMAIN;
export var items = [
    {
        title: "com.s7.tab.navigation.main.page",
        value: NavigationStep.MAIN,
        href: "/",
        icon: NewSlimHomeLinear,
        as: 'a',
    },
    {
        title: "com.s7.tab.navigation.trips.page",
        value: NavigationStep.TRIPS,
        href: "/?tab=".concat(NavigationStep.TRIPS),
        icon: NewSlimCompassLinear,
        as: 'a',
    },
    {
        title: "com.s7.tab.navigation.search.page",
        value: NavigationStep.SEARCH,
        href: "/?tab=".concat(NavigationStep.SEARCH),
        icon: function (_a) {
            var active = _a.active, props = __rest(_a, ["active"]);
            return active ? (React.createElement(NewSlimSearchTicketFilled, __assign({}, props))) : (React.createElement(NewSlimSearchTicketLinear, __assign({}, props)));
        },
        as: 'a',
    },
    {
        title: "com.s7.tab.navigation.info.page",
        value: NavigationStep.INFO,
        href: "/?tab=".concat(NavigationStep.INFO),
        icon: NewSlimInfoLinear,
        as: 'a',
    },
    {
        title: "com.s7.tab.navigation.profile.page",
        value: NavigationStep.PROFILE,
        href: NEW_PROFILE_DOMAIN,
        icon: NewSlimProfileLinear,
        as: 'a',
    },
];
export var LITE_PATH = "/lite/";
