import 'whatwg-fetch';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { PortalHeader, fillProfileData, hideChat, setChatEnabled } from '@s7ui-kit/s7ui-kit';
import Footer from './Footer/Footer';
import { loadSettings } from '../../services/services/SettingsService';
import { isChromeBelow75 } from '../../services/utils/isChromeBelow75';
import getProfileName from '../../services/utils/getProfileName';
import { isEmpty } from '../../services/utils/utils.ts';
import { isStandalone } from '@s7/platform';
import qs from 'query-string';
import { FooterType } from "../../services/types/footer";
import NewPortalHeader from './NewPortalHeader/ui/NewPortalHeader';
import {TabNavigation} from '../../fsd-structure/widgets/tab-navigation';

import '@s7ui-kit/s7ui-kit/build/style.css';

import SiteAlerts from "./SiteAlerts";
import CookiePolicy from '../../components/common/CookiePolicy';
import MetaTagsHelmet from '../../components/common/MetaTagsHelmet';
import scrollPage from "../../services/utils/scrollPage";
import ModalUpdateBrowser from './ModalUpdateBrowser';
import Cookies from 'js-cookie';

import { clickChatButton } from '@s7ui-kit/s7ui-kit';
import { ZendeskChat } from './ZendeskChat';
import { analytics } from '../../services/analytics/analyticsManager';

let isFirstMount = true;

const mapStateToProps = state => ({
    lang: state.App.lang,
    oldLang: state.App.oldLanguage,
    supportedLanguages: state.App.supportedLanguages,
    profile: state.User.profile,
    deviceWidth: state.App.deviceWidth,
    isChatClicked: state.User.isChatClicked,
    pageInfo: state.PageInfo.pageInfo,
    isChatEnabled: state.App.isChatEnabled,
});

const mapDispatchToProps = dispatch => ({
    fillProfileData: (data) => {
        const actionResult = fillProfileData({ data });

        dispatch(actionResult);

        return dispatch(actionResult);
    },
    hideChat: () => dispatch(hideChat()),
    clickChatButton: () => dispatch(clickChatButton()),
    setChatEnabled: isChatEnabled => dispatch(setChatEnabled(isChatEnabled)),
});

const Layout = withRouter(connect(mapStateToProps, mapDispatchToProps)(class extends Component {
    state = {
        showHeader: true,
        showTabs: true,
        showFooter: true,
        showChat: true,
        showLoginWidget: false,
        showCookiePolicy: true,
        showBrowserOld: false,
        isVKPage: false,
        visitedPortal: false
    };

    componentWillMount() {
        const { location } = this.props;
        const locationSearch = qs.parse(location.search);

        if ('mobile' in locationSearch) {
            this.setState({ showHeader: false, showFooter: false, showCookiePolicy: false, showChat: false, showTabs: false });
        }

        if ('vkpage' in locationSearch) {
            this.setState({ isVKPage: true, showFooter: false, showCookiePolicy: false });
        }

        if (window.location.pathname.indexOf('/info/routes/') !== -1 || window.location.pathname.indexOf('vkpage') !== -1) {
            this.setState({ showFooter: false });
        }
    }

    componentDidMount() {
        const { lang, location, history, supportedLanguages, oldLang, pageInfo } = this.props;

        const re = new RegExp(`^/(${supportedLanguages.join('|')})/`);
        const s7PageInit = typeof (window.s7PageInit) !== 'undefined' ? window.s7PageInit : {};
        const language = String(lang).replace(/(es|it|de)/g, 'en');
        const visitedPortal = Boolean(Number(Cookies.get('portal')))

        if (re.test(window.location.pathname) && supportedLanguages.length > 0) {
            const pathname = window.location.pathname.replace(re, `/${language}/`);
            history.push(`${pathname}${window.location.search}${window.location.hash}`);
        } else if (!isFirstMount && lang !== oldLang && supportedLanguages.includes(oldLang)) {
            history.push(`/${language}${location.pathname}${window.location.search}${window.location.hash}`);
        } else if (!re.test(window.location.pathname) && supportedLanguages.length > 0) {
            const pathname = window.location.pathname.replace(new RegExp(`^(/(es|it|de)/|/(es|it|de))`), `/en/`);
            history.push(`${pathname}${window.location.search}${window.location.hash}`);
        }

        isFirstMount = false;

        if (window.document.documentMode || isChromeBelow75()) {
            this.setState({ showBrowserOld: true });
        }

        this.props.history.listen(location => {
            try {
                if (window.ga) {
                    window.ga(
                        'create', process.env.NODE_BUILD_ENV === 'production' ? 'GTM-MWTMZW' : 'GTM-KRJNC2', 'auto'
                    );
                    const tracker = window.ga.getAll()[0];

                    tracker.send('pageview');
                }

                if (window.yaCounter4173733) window.yaCounter4173733.hit(location.pathname);
            } catch (e) {
                console.log(e);
            }
        });

        setTimeout(() => {
            if (document.querySelector('.page-content')) document.querySelector('.page-content').style.minHeight = 'initial';
        }, 5000);

        //глобально доскрол к Container по id по клику на элемент с href === hash
        document.body.addEventListener('click', (e) => {
            if (!!window.location.hash && e.target && e.target.href && e.target.href.indexOf(window.location.hash) !== -1) {
                e.preventDefault();
                if (document.querySelector(window.location.hash)) {
                    scrollPage(document.querySelector(window.location.hash));
                }
            }
        });

        this.checkChatValidation();

        if (s7PageInit?.page?.[s7PageInit?.pageLanguage]?.noTemplate
            || (pageInfo?.noTemplate)) {
            this.setState({ showHeader: false, showFooter: false, showCookiePolicy: false, showTabs: false });
        }

        this.setState({ visitedPortal });
    };

    componentWillReceiveProps = nextProps => {
        const { pageInfo } = this.props;
        if (nextProps.pageInfo && nextProps.pageInfo.noTemplate && pageInfo.noTemplate !== nextProps.pageInfo.noTemplate) {
            this.setState({ showHeader: false, showFooter: false, showCookiePolicy: false, showTabs: false });
        }
    };

    minimizeChat = () => {
        this.props.hideChat();
    };

    loggedInUser = () => {
        const { profile } = this.props;
    };

    setLoginWidget = () => {
        return this.state.showLoginWidget;
    };

    checkChatVisible = (chatData) => {
        let isExceptMatch = false;

        let exceptionUrls = window?.screen?.width > 767 ? chatData?.exceptionUrls || '' : chatData?.exceptionUrlsMobile || '';
        exceptionUrls = exceptionUrls.split('\r\n');
        exceptionUrls.forEach(url => {
            if (!!url && window.location.href.match(url)) {
                isExceptMatch = true;
            }

            if (url.includes('$') && window.location.href.includes(url.replace('$', ''))) {
                isExceptMatch = true;
            }
        })

        if (isExceptMatch) {
            return this.props.setChatEnabled(false);
        }

        if (location?.search?.indexOf('open-chat') > 0) {
            return this.props.setChatEnabled(true);
        }

        if (window.screen.width > 767) {
            return this.props.setChatEnabled(true);
        }
        return location.pathname === `/${this.props.lang}/` || location.pathname === '/' ? this.props.setChatEnabled(false) : this.props.setChatEnabled(true);
    }

    checkOpenChat = () => {
        const { location } = this.props;

        if (location?.search?.indexOf('open-chat') > 0) {
            this.props.clickChatButton();
        }
    }

    checkChatValidation = () => {
        const s7PageInit = typeof (window.s7PageInit) !== 'undefined' ? window.s7PageInit : {};
        const { lang } = this.props;

        if (s7PageInit.settings) {
            if (s7PageInit.settings.chat && s7PageInit.settings.chat.enabled) {
                this.checkChatVisible(s7PageInit.settings.chat);
                this.checkOpenChat();
            }

            if (s7PageInit.settings.auth && s7PageInit.settings.auth.loginWidget) {
                this.setState({ showLoginWidget: true });
                window.showLoginWidget = true;
            }
        } else {
            loadSettings({ lang: lang, ns: 'default' }).then(response => {
                if (!response) {
                    return;
                }

                if (response.chat && response.chat.enabled) {
                    this.checkChatVisible(response.chat);
                    this.checkOpenChat();
                }

                if (response.auth && response.auth.loginWidget) {
                    this.setState({ showLoginWidget: true });
                    window.showLoginWidget = true;
                }
            }).catch(error => console.log(error));
        }
    }
    render() {
        const { lang, allSupportedLanguages, children, profile, isChatClicked, deviceWidth, isChatEnabled, showCurrency } = this.props;
        const { showHeader, showFooter, showCookiePolicy, showChat, showBrowserOld, isVKPage, visitedPortal, showTabs } = this.state;
        let chatProfile = {};

        const mainPagExp = new RegExp(`^/(?:[a-z]{2}/?)?$`);

        if (!isEmpty(profile)) {
            const { names } = profile;
            const profileName = getProfileName({ names, lang, asObject: true });

            chatProfile = {
                ...chatProfile,
                firstName: profileName?.firstName || '',
                lastName: profileName?.lastName || '',
                ...profile
            }
        }
        return (
            <div className="App page">
                {showCookiePolicy && !isStandalone && <CookiePolicy />}
                <MetaTagsHelmet {...this.props} isMainPage={mainPagExp.test(window.location.pathname)} />
                {isVKPage && <div className={'vkpage__helper'}></div>}
                {visitedPortal ?
                    showHeader && <NewPortalHeader /> :
                    <PortalHeader
                        loggedInUser={this.loggedInUser}
                        showHeader={showHeader}
                        showLoginWidget={this.setLoginWidget}
                        showCurrency={showCurrency}
                        analytics={analytics}
                    />
                }
                {!isVKPage && <SiteAlerts lang={lang} allSupportedLanguages={allSupportedLanguages} />}
                {showBrowserOld && !Cookies.get('isBrowserOld') && deviceWidth > 767 &&
                    <ModalUpdateBrowser />
                }
                <div className="page-content">
                    {children}
                </div>
                {showFooter && <Footer
                    code={isStandalone ? FooterType.WEBAPP : FooterType.DEFAULT}
                    clickChatButton={this.props.clickChatButton}
                    lang={lang}
                    isMainPage={mainPagExp.test(window.location.pathname)}
                />}
                {!isVKPage &&
                    <React.Suspense fallback={<div />}>
                        <ZendeskChat
                            profile={chatProfile}
                            minimizeChatHandle={this.minimizeChat}
                            isChatOpen={isChatClicked}
                            isExperiment={true}
                            isChatEnabled={isChatEnabled && showChat}
                            checkChatValidation={this.checkChatValidation}
                            isHiddenIcon={visitedPortal}
                            deviceWidth={deviceWidth}
                        />
                    </React.Suspense>}
                {visitedPortal && showTabs && <TabNavigation />}
            </div>
        )
    }
}));

Layout.propTypes = {
    children: PropTypes.node,
    lang: PropTypes.string,
};

export default Layout;