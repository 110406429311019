var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { injectIntl } from "@s7ui-kit/s7ui-kit";
import { useLocation } from "react-router-dom";
import { TabNavigationWidget } from "@s7/navigation";
import { items, LITE_PATH } from "../constants";
import { NavigationStep } from "../types";
import { analytics } from "../../../../services/analytics/analyticsManager";
export var TabNavigation = injectIntl(function (_a) {
    var words = _a.words, getMessage = _a.getMessage;
    var pathname = useLocation().pathname;
    var activeTab = pathname.includes(LITE_PATH)
        ? NavigationStep.SEARCH
        : NavigationStep.PAGE;
    var tabs = items.map(function (tab) { return (__assign(__assign({}, tab), { title: getMessage(words, tab.title) })); });
    return (React.createElement(TabNavigationWidget, { analytics: analytics, data: tabs, value: activeTab }));
});
